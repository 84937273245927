import {StatusFilter} from '@wix/events-types'
import type {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {EVENT_FILTER_TYPE} from '@wix/wix-events-commons-statics'
import settingsParams from '../../settingsParams'
import type {State, WidgetComponentConfig} from '../types/state'
import {getStatusFilter} from '../../utils/status-filter'
import {getDynamicCategoryIds, isVeloOverrideUsed} from './velo-overrides'

export const getFilterTypeFromSettings = (state: State, flowAPI: ControllerFlowAPI) => {
  const filter = flowAPI.settings.get(settingsParams.filterType) as number

  if (isVeloOverrideUsed(state)) {
    return EVENT_FILTER_TYPE.UPCOMING_AND_PAST
  }

  return filter
}

export const getComponentStatusFilter = (state: State, flowAPI: ControllerFlowAPI) => {
  if (isVeloOverrideUsed(state)) {
    return StatusFilter.FUTURE_AND_PAST
  }

  const categoryId = getDynamicCategoryIds(state, flowAPI)
  const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number

  let filter: StatusFilter

  if (filterTypeFromSettings === undefined) {
    if (categoryId) {
      filter = state.component.byCategoryId?.statusFilter
    } else {
      filter = state.component.byStatus?.filter
    }
  } else {
    filter = getStatusFilter(filterTypeFromSettings)
  }

  return filter
}

export const getComponentCategoryIds = (state: WidgetComponentConfig) => state.byCategoryId?.categoryId ?? null

export const isManualFilterSelected = (state: State, flowAPI: ControllerFlowAPI) => {
  const filterTypeFromSettings = flowAPI.settings.get(settingsParams.filterType) as number

  return filterTypeFromSettings !== undefined
    ? filterTypeFromSettings === EVENT_FILTER_TYPE.MANUAL
    : Boolean(state.component.byEventId)
}
